import { FC, HTMLProps, useEffect } from "react";
import styles from "./RequestScreen.module.scss";
import classNames from "classnames";
import Preview from "../../components/Preview";
import Spinner from "../../components/Spinner";
import useRequest from "../../utils/hooks/useRequest";
import { useDispatch, useSelector } from "../../store/hooks";
import { Button } from "@mui/material";
import { setAppState } from "../../store/reducers/globalSlice";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const RequestScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const { t } = useTranslation();
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { loading, makeRequest, error } = useRequest();
  const { fileSrc } = useSelector((state) => state.file);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const debugModeIsOn = searchParams.get("debug") == "true";

  const toInitialScreen = () => {
    dispatch(setAppState("initial"));
  };

  useEffect(() => {
    makeRequest(fileSrc, debugModeIsOn);
  }, []);

  return (
    <div className={className}>
      <Preview />
      {loading && (
        <div className={styles["loader"]}>
          <Spinner />
        </div>
      )}
      {error && <Button onClick={toInitialScreen}>{t("button.try")}</Button>}
    </div>
  );
};

export default RequestScreen;
