import Navigation from "./components/Navigation/Navigation";
import BottomSection from "./components/BottomSection/BottomSection";
import ConfirmContext from "./components/Warning/ConfirmContext";
import Warning from "./components/Warning/Warning";
import Main from "./components/ScreenContainer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    (window as any).ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);
  return (
    <ConfirmContext>
      <div className="app-container">
        <div className="max-width">
          <Navigation />
          <Main />
        </div>
        <Warning />
        <BottomSection addClasses={["bottom-section"]} />
      </div>
    </ConfirmContext>
  );
}

export default App;
