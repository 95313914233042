import { FC, HTMLProps } from "react";
import styles from "./GlobalWarningCard.module.scss";
import classNames from "classnames";

export type Props = {
  title: string;
  warnings: string[];
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const GlobalWarningCard: FC<Props> = (props) => {
  const { title, warnings, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <h3 className={styles["warning-title"]}>{title}</h3>
      <div className={styles["warnings"]}>
        {warnings.map((w) => {
          return (
            <div className={styles["warning"]} key={w}>
              <span className={styles["bullet"]}></span>
              <p>{w}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GlobalWarningCard;
