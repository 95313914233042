import { FC, HTMLProps } from "react";
import styles from "./Notification.module.scss";
import classNames from "classnames";
import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../store/hooks";
import { showNotif } from "../../store/reducers/notificationSlice";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

type TransitionProps = Omit<SlideProps, "direction">;

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const Notification: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { t } = useTranslation();
  const { message } = useSelector((state) => state.notif);
  const { type } = useSelector((state) => state.notif);
  const { show } = useSelector((state) => state.notif);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showNotif(false));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={type === "info" ? 500 : null}
      TransitionComponent={Transition}
      onClose={handleClose}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      className={className}
    >
      <Alert severity={type} sx={{ width: "100%" }} onClose={handleClose}>
        {t(`notification.${message}`)}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
