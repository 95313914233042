import { FC, SVGProps } from "react";
import styles from "./Field.module.scss";

export type Props = {
  imageProps: { src: string; width: number; height: number; rotate?: boolean };
  addClasses?: string[];
} & SVGProps<SVGSVGElement>;

const FieldImage: FC<Props> = (props) => {
  const { imageProps, ...svgProps } = props;
  const { src, width, height, rotate } = imageProps;
  return (
    <svg className={styles["img"]} {...svgProps}>
      <image
        href={src}
        width={width}
        height={height}
        style={{
          // rotating image tag instead of svg since rotating svg remains extra space
          transform: rotate ? "rotate(-90deg)" : "none",
          transformOrigin: "bottom left",
        }}
      />
    </svg>
  );
};

export default FieldImage;
