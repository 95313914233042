import { FC, HTMLProps } from "react";
import styles from "./BooleanField.module.scss";
import classNames from "classnames";
import { ReactComponent as Check } from "../../assets/imgs/check.svg";
import { ReactComponent as Cross } from "../../assets/imgs/cross.svg";
import Chip from "../Chip";

export type Props = {
  name: string;
  isPositive: boolean;
  selected?: boolean;
  warnings?: string[];
  isRed?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const BooleanField: FC<Props> = (props) => {
  const { name, isPositive, isRed, warnings, selected, children, addClasses } =
    props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["selected"]]: selected }
  );

  const iconClass = classNames([styles["icon"]], {
    [styles["icon--red"]]: isRed,
  });

  return (
    <div className={className} {...props}>
      <div className={styles["info"]}>
        <span className={styles["field-name"]}>{name}</span>
        {isPositive ? (
          <Check className={iconClass} />
        ) : (
          <Cross className={iconClass} />
        )}
      </div>
      {children && <div className={styles["children"]}>{children}</div>}
      {warnings ? (
        <div className={styles["warnings"]}>
          {warnings.map((w) => {
            return <Chip label={w} />;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default BooleanField;
