import { FC, HTMLProps } from "react";
import styles from "./ScreenContainer.module.scss";
import classNames from "classnames";
import Notification from "../Notification";
import InitialScreen from "../../Screens/InitialScreen";
import { useSelector } from "../../store/hooks";
import RequestScreen from "../../Screens/RequestScreen";
import ResultScreen from "../../Screens/ResultScreen";

export type Props = {
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const ScreenContainer: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { currentState } = useSelector((state) => state.appState);

  return (
    <div className={className} {...props}>
      {currentState === "initial" && <InitialScreen />}
      {currentState === "request" && <RequestScreen />}
      {currentState === "result" && <ResultScreen />}
      <Notification />
    </div>
  );
};

export default ScreenContainer;
