import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          button: {
            try: "Upload another document",
          },
          warning: {
            title: "Warning",
            text: "Uploaded photos will be sent to server for recognition!",
            cancel: "Cancel",
          },
          warnings: {
            BAD_CONTENT: "Incorrect content",
            BIRTH_DATE_CHECK_DIGIT_MISMATCH: "Birth date check digit mismatch",
            BIRTH_ISSUE_DATES_INCONSISTENCY:
              "Birth and issue dates are insonsistent",
            COMMA_INSTEAD_OF_DOT: "Comma instead of dot",
            COMPOSITE_CHECK_DIGIT_MISMATCH: "Composite check digit mismatch",
            DAY_EQUALS_ZERO: "Day equals zero",
            DAY_IS_INVALID: "Day is invalid",
            EXTRA_DATA_CHECK_DIGIT_MISMATCH: "Extra data check digit mismatch",
            FITTED_IN_MRZ_INCORRECTLY: "MRZ mismatch",
            HANDWRITTEN_TEXT_NOT_EXPECTED: "Handwritten text not expected",
            ISSUE_DATE_IN_FUTURE: "Incorrect issue date",
            ISSUE_DATE_SERIAL_NUMBER_INCONSISTENCY: {
              issue_date: "Severe inconsistency with issue date",
              serial_number: "Severe inconsistency with form printing date",
            },
            ISSUER_CODE_INCONSISTENCY:
              "Issuer code inconsistency. The most similar issuer: {{issuer}}",
            serial_number_handwritten_inconsistency:
              "Serial number conflicts handwritten filling",
            MONTH_EQUALS_ZERO: "Month equals zero",
            MONTH_MORE_THAN_12: "Month more than 12",
            MRZ_NOT_EXPECTED: "MRZ shouldn't be present",
            MRZ_MISMATCH: "MRZ mismatch",
            PASSPORT_NUMBER_CHECK_DIGIT_MISMATCH:
              "Passport number check digit mismatch",
            PREDICTED_DOC_AGE_IS_MUCH_HIGHER_THAN_REAL:
              "The age of the document, predicted by the degree of wear, is higher than the age calculated by the date of issue. Predicted: {{predicted}}, calculated: {{real}}",
            PREDICTED_DOC_AGE_IS_MUCH_LOWER_THAN_REAL:
              "The age of the document, predicted by the degree of wear, is lower than the age calculated by the date of issue. Predicted: {{predicted}}, calculated: {{real}}",
            REQUIRED_FIELD_IS_MISSING: "Required field is missing",
            SERIAL_NUMBER_CHECK_DIGIT_MISMATCH:
              "Serial number check digit mismatch",
            SERIAL_NUMBER_LESS_000101: "Incorrect number",
            TRUNCATED: "Truncated",
            UNKNOWN_UNIT_CODE: "Unknown unit code",
            UNKNOWN_ISSUER_CODE: "Unknown issuer code",
            UNRELIABLE_BEGINNING_LOCATION: "Unreliable beggining location",
            UNRELIABLE_END_LOCATION: "Unreliable end location",
            UNRELIABLE_LOCATION: "Unreliable end location",
            WITHOUT_TRAILING_DOT: "Without trailing dot",
            mrz_first_line_not_parsed_prefix:
              "Couldn't parse MRZ first line prefix",
            mrz_second_line_not_parsed_prefix:
              "Couldn't parse MRZ second line prefix",
            mrz_second_line_not_parsed_suffix:
              "Couldn't parse MRZ second line suffix",
            no_stamp: "The stamp must be present",
            RF_SIGN_EXPECTED: "The RF sign must be present",
            RF_SIGN_NOT_EXPECTED: "The RF sign must not be present",
            suspicious_age: "Suspiciously old age",
            OKATO_GAI_PAIR_DOES_NOT_EXIST:
              'The pair "OKATO code (first two digits of the passport series) - GAI code (first two digits of the code of the issuing unit)" indicated in the passport does not exist in the directory "Codes of subjects of the Russian Federation".',
          },
          dragAndDrop: {
            default: "Drop your image here\nor click for selection",
            file_limit_exceeded: "Max {{limit}} file(s) allowed",
            type_not_allowed:
              "The file is not allowed. Allowed types: {{types}}",
            upload: "Drop image for uploading to server",
          },
          notification: {
            wrongFileType: "File should be an image or a PDF file!",
            errorImageLoad: "Error loading image!",
            nothingDetected: "No documents were found!",
            wentWrong: "Something went wrong!",
            "common.ImageDecodeError": "Failed to decode image!",
            "common.PDFHasNoAcceptableImages": "PDF has no acceptable images!",
            "common.ImageIsTooLarge":
              "Image has area which is more than maximal allowed area 40 megapixels",
            "common.PDFHasTooManyImages":
              "PDF has number of images which is more than maximal allowed number of 10 images",
            "common.PDFFailedToExtractImages":
              "Failed to extract images from PDF",
            "common.QuerystringValidationError": "Something went wrong!",
            copied: "Copied",
            "photo.error": "Camera connection error",
            BELARUSIAN_PASSPORT_P30_P31:
              "Detected: Belarusian Passport (30-31)",
            BELARUSIAN_PASSPORT_P32: "Detected: Belarusian Passport (32)",
            BELARUSIAN_PASSPORT_P32_P33:
              "Detected: Belarusian Passport (32-33)",
            BELARUSIAN_PASSPORT_P33: "Detected: Belarusian Passport (33)",
            BELARUSIAN_PASSPORT_NOTES: "Detected: Belarusian Passport Notes",
            KAZAKHSTAN_ID_1994_FRONT:
              "Detected: KAZAKHSTAN ID 1994 (front side)",
            KAZAKHSTAN_ID_2008_FRONT:
              "Detected: KAZAKHSTAN ID 2008 (front side)",
            KAZAKHSTAN_ID_2008_BACK: "Detected: KAZAKHSTAN ID 2008 (back side)",
            KAZAKHSTAN_ID_2014_FRONT:
              "Detected: KAZAKHSTAN ID 2014 (front side)",
            KAZAKHSTAN_ID_2014_BACK: "Detected: KAZAKHSTAN ID 2014 (back side)",
            KYRGYZ_ID_2004_BACK: "Detected: KYRGYZ_ID_2004_BACK",
            KYRGYZ_ID_2004_FRONT: "Detected: KYRGYZ_ID_2004_FRONT",
            KYRGYZ_ID_2017_BACK: "Detected: KYRGYZ_ID_2017_BACK",
            KYRGYZ_ID_2017_FRONT: "Detected: KYRGYZ_ID_2017_FRONT",
            KYRGYZ_PASSPORT_2006_P30_P31:
              "Detected: KYRGYZ_PASSPORT_2006_P30_P31",
            KYRGYZ_PASSPORT_2006_P31: "Detected: KYRGYZ_PASSPORT_2006_P31",
            KYRGYZ_PASSPORT_2006_OTHER: "Detected: KYRGYZ_PASSPORT_2006_OTHER",
            RUSSIAN_PASSPORT_P2_P3: "Detected: Russian Passport (2-3)",
            RUSSIAN_PASSPORT_P2: "Detected: Russian Passport (2)",
            RUSSIAN_PASSPORT_P3: "Detected: Russian Passport (3)",
            RUSSIAN_SNILS_BACK: "Detected: RUSSIAN_SNILS_BACK",
            RUSSIAN_SNILS_FRONT: "Detected: RUSSIAN_SNILS_FRONT",
            DRIVER_LICENSE_FRONT: "Detected: Driver License (front side)",
            DRIVER_LICENSE_BACK: "Detected: Driver License (back side)",
            VEHICLE_REGISTRATION_FRONT:
              "Detected: Vehicle registration (front side)",
            VEHICLE_REGISTRATION_BACK:
              "Detected: Vehicle registration (back side)",
            unknownDocType: "The document type is unknown",
          },
          "bottom-section": {
            text: "Computer Vision by ",
          },
          fields: {
            address_federal_subject: "Federal subject",
            address_settlement: "Settlement",
            address_district: "District",
            address_street: "Street",
            address_street_number: "Street number",
            address_building_number: "Building number",
            address_apartment_number: "Apartment number",
            authority: "Authority",
            birth_date: "Birth date",
            birth_date_check_digit: "Birth date check digit",
            birthplace: "Birth place",
            birthplace_en: "Birth place (en)",
            birthplace_ru: "Birth place (ru)",
            category: "Category",
            category_a: "Category A",
            category_b: "Category B",
            category_c: "Category C",
            category_d: "Category D",
            category_e: "Category E",
            citizenship: "Citizenship",
            citizenship_en: "Citizenship (en)",
            code: "Code",
            composite_check_digit: "Composite check digit",
            document_code: "Type",
            document_type: "Type",
            document_type_issuing_state_code:
              "Document type issuing state code",
            expiry_date: "Expiry date",
            expiry_date_check_digit: "Expiry date check digit",
            extra_data_check_digit: "Extra data check digit",
            first_and_middle_name: "First and middle name",
            first_middle_name: "First and middle name",
            first_name: "First name",
            first_name_en: "First name (en)",
            first_name_ru: "First name (ru)",
            first_name_latin: "First name (Latin)",
            gender: "Gender",
            is_grayscale: "Is grayscale",
            is_handwritten: "Is handwritten",
            issue_date: "Issue date",
            issue_place: "Issue place",
            issuer: "Issuer",
            issuer_en: "Issuer (en)",
            issuer_ru: "Issuer (ru)",
            issuer_code: "Issuer code",
            issuing_state_code: "Code of issuing state",
            last_name: "Last name",
            last_name_en: "Last name (en)",
            last_name_ru: "Last name (ru)",
            last_name_latin: "Last name (Latin)",
            marital_status: "Maritial status",
            middle_name: "Middle name",
            middle_name_ru: "Middle name (ru)",
            mrz: "Machine readable zone",
            nationality: "Nationality",
            passport_number_check_digit: "Passport number check digit",
            personal_number: "Identification №",
            photo: "Photo",
            serial_number: "Serial number",
            serial_number_check_digit: "Serial number check digit",
            signature: "Signature",
            special_marks: "Special marks",
            symbol_on_position_42: "Symbol on position 42",
            type_and_issue_state: "Type and issue state",
            registration_date: "Registration date",
            regulations_compliance_number: "Regulations compliance number",
            residence_place: "Residence place",
            rf_sign: "Has the Russian Federation sign",
            has_stamp: "Has the stamp",
            valid_until: "Valid until",
            vehicle_license_plate: "License plate",
            vehicle_identification_number: "VIN",
            vehicle_brand: "Brand",
            vehicle_model: "Model",
            vehicle_body_type: "Body type",
            vehicle_category: "Category",
            vehicle_manufacture_year: "Manufacture year",
            vehicle_chassis_number: "Chassis number",
            vehicle_body_number: "Body number",
            vehicle_color: "Color",
            vehicle_engine_power: "Engine power",
            vehicle_emissions_class: "Emissions class",
            vehicle_certificate_number: "Certificate number",
            vehicle_gross_combined_weight_rating:
              "Gross combined weight rating",
            vehicle_curb_weight: "Curb weight",
            unit_code: "Unit code",
            unit_name: "Unit name",
            custom_names: {
              RUSSIAN_SNILS_FRONT: {
                personal_number: "Personal number",
              },
              RUSSIAN_SNILS_ADIREG: {
                personal_number: "Personal number",
              },
            },
          },
          language: {
            switch: "РУ",
          },
          photo: {
            connecting: "Connecting the camera...",
            make_photo: "Take a picture with camera",
            error: "Camera error",
          },
          demo: {
            name: "Documents recognition",

            description_1:
              "The Tevian Doc SDK document recognition module allows you to extract content from structured documents. Together with extensive integration capabilities, this technology will allow you to digitize, speed up and automate many processes related to document flow. Download one of the supported documents and evaluate the quality of text recognition and field classification.",
            description_2: {
              title: "Supported documents include:",
              passports_title: "Passports:",
              russian: "of the Russian Federation",
              belarusian: "of the Republic of Belarus",
              kyrgyz: "of the Kyrgyz Republic",
              kazahstan: "of the Republic of Kazakhstan",
              id_cards_title: "ID cards:",
              snils_rf: "Russian insurance certificate",
              driver_id_rf: "Russian driver's license",
              insurance_rf: "Russian vehicle registration certificate",
            },
          },
          policy: "Privacy policy",
          titles: {
            mrz_warnings: "Document parsing errors",
            mrz_fields: "MRZ fields",
            global_warnings: "Global warnings",
            text_unsupported:
              "This document unfold does not support field recognition",
            unable_to_display:
              "The document type can't be displayed in the demo if uploaded as PDF",
          },
        },
      },
      ru: {
        translation: {
          button: {
            try: "Загрузить другой документ",
          },
          warning: {
            title: "Предупреждение",
            text: "Загруженные фото будут отправлены на сервер для распознавания!",
            cancel: "Отмена",
          },
          warnings: {
            BAD_CONTENT: "Содержимое некорректно",
            BIRTH_DATE_CHECK_DIGIT_MISMATCH:
              "Найдено несовпадение с контрольной цифрой даты рождения",
            BIRTH_ISSUE_DATES_INCONSISTENCY:
              "Несоответствие между датой рождения и датой выдачи",
            COMMA_INSTEAD_OF_DOT: "Запятая вместо точки в конце текста",
            COMPOSITE_CHECK_DIGIT_MISMATCH:
              "Найдено несовпадение с заключительной контрольной цифрой",
            DAY_EQUALS_ZERO: "День равен нулю",
            DAY_IS_INVALID:
              "День недопустим для соответствующего месяца и года",
            EXTRA_DATA_CHECK_DIGIT_MISMATCH:
              "Найдено несовпадение с контрольной цифрой дополнительных элементов данных",
            FITTED_IN_MRZ_INCORRECTLY: "Несовпадение с МЧЗ",
            HANDWRITTEN_TEXT_NOT_EXPECTED:
              "Данный тип документа не должен содержать рукописный текст",
            ISSUE_DATE_IN_FUTURE: "Некорректная дата выдачи",
            ISSUE_DATE_SERIAL_NUMBER_INCONSISTENCY: {
              issue_date: "Сильное расхождение с датой печати бланка",
              serial_number: "Сильное расхождение с датой выдачи",
            },
            serial_number_handwritten_inconsistency:
              "Серия документа конфликтует с рукописным заполнением",
            ISSUER_CODE_INCONSISTENCY:
              "Наименование подразделения не соответствует коду. Наиболее близкое наименование: {{issuer}}",
            MONTH_EQUALS_ZERO: "Месяц равен нулю",
            MONTH_MORE_THAN_12: "Номер месяца больше 12",
            MRZ_NOT_EXPECTED: "МЧЗ не должна присутствовать",
            MRZ_MISMATCH: "Несовпадение с МЧЗ",
            PASSPORT_NUMBER_CHECK_DIGIT_MISMATCH:
              "Найдено несовпадение с контрольной цифрой серии и номера",
            PREDICTED_DOC_AGE_IS_MUCH_HIGHER_THAN_REAL:
              "Возраст документа, распознанный по степени износа, больше возраста, рассчитанного по дате выдачи. Распознанный: {{predicted}}, рассчитанный: {{real}}",
            PREDICTED_DOC_AGE_IS_MUCH_LOWER_THAN_REAL:
              "Возраст документа, распознанный по степени износа, меньше возраста, рассчитанного по дате выдачи. Распознанный: {{predicted}}, рассчитанный: {{real}}",
            REQUIRED_FIELD_IS_MISSING: "Обязательное поле отсутствует",
            SERIAL_NUMBER_LESS_000101: "Некорректный номер",
            SERIAL_NUMBER_CHECK_DIGIT_MISMATCH:
              "Найдено несовпадение с контрольной цифрой серии и номера",
            TRUNCATED: "Текст поля обрезан в конце",
            UNKNOWN_ISSUER_CODE: "Код подразделения не найден среди известных",
            UNKNOWN_UNIT_CODE: "Неизвестный код подразделения",
            WITHOUT_TRAILING_DOT: "Точка в конце текста не найдена",
            UNRELIABLE_BEGINNING_LOCATION:
              "Не удалось надежно определить начало поля в МЧЗ строке",
            UNRELIABLE_END_LOCATION:
              "Не удалось надежно определить конец поля в МЧЗ строке",
            UNRELIABLE_LOCATION:
              "Не удалось надежно определить позицию поля в МЧЗ строке",
            mrz_first_line_not_parsed_prefix:
              "Не удалось разобрать префикс первой строки МЧЗ",
            mrz_second_line_not_parsed_prefix:
              "Не удалось разобрать префикс второй строки МЧЗ",
            mrz_second_line_not_parsed_suffix:
              "Не удалось разобрать суффикс второй строки МЧЗ",
            no_stamp: "Отсутствует штамп",
            RF_SIGN_EXPECTED: "На данном бланке должен быть знак “РФ”",
            RF_SIGN_NOT_EXPECTED: "На данном бланке не должно быть знака “РФ”",
            suspicious_age: "Подозрительно большой возраст",
            OKATO_GAI_PAIR_DOES_NOT_EXIST:
              'Указанная в паспорте пара “код ОКАТО (две первые цифры серии паспорта) - код ГАИ (две первые цифры кода подразделения выдачи)” не существует в справочнике "Коды субъектов Российской Федерации".',
          },
          dragAndDrop: {
            default: "Переместите изображение\nили кликните для выбора",
            file_limit_exceeded:
              "Максимально допустимое количество файлов: {{limit}}",
            type_not_allowed:
              "Недопустимое разрешение файла. Допустимые разрешения: {{types}}",
            upload: "Отпустите изображение для загрузки на сервер",
          },
          notification: {
            wrongFileType: "Файл должен быть изображением или PDF!",
            errorImageLoad: "Ошибка при загрузке изображения!",
            nothingDetected: "Документов не обнаружено!",
            wentWrong: "Что-то пошло не так!",
            "common.ImageDecodeError": "Не удалось раскодировать изображение!",
            "common.PDFHasNoAcceptableImages":
              "PDF не содержит допустимых изображений!",
            "common.ImageIsTooLarge":
              "Площадь картинки превышает максимальную - 40 мегапикселей",
            "common.PDFHasTooManyImages":
              "Количество картинок в PDF превышает максимальное - 10 картинок",
            "common.PDFFailedToExtractImages":
              "Не удалось извлечь изображения из PDF",
            "common.QuerystringValidationError": "Что-то пошло не так!",
            copied: "Скопировано",
            "photo.error": "Ошибка подключения камеры",
            BELARUSIAN_PASSPORT_P30_P31:
              "Распознано: Белорусский паспорт (30-31)",
            BELARUSIAN_PASSPORT_P32: "Распознано: Белорусский паспорт (32)",
            BELARUSIAN_PASSPORT_P32_P33:
              "Распознано: Белорусский паспорт (32-33)",
            BELARUSIAN_PASSPORT_P33: "Распознано: Белорусский паспорт (33)",
            BELARUSIAN_PASSPORT_NOTES:
              "Распознано: Белорусский паспорт отметки",
            KAZAKHSTAN_ID_1994_FRONT:
              "Распознано: Казахстанский паспорт 1994 (лицевая сторона)",
            KAZAKHSTAN_ID_2008_FRONT:
              "Распознано: Казахстанский паспорт 2008 (лицевая сторона)",
            KAZAKHSTAN_ID_2008_BACK:
              "Распознано: Казахстанский паспорт 2008 (оборотная сторона)",
            KAZAKHSTAN_ID_2014_FRONT:
              "Распознано: Казахстанский паспорт 2014 (лицевая сторона)",
            KAZAKHSTAN_ID_2014_BACK:
              "Распознано: Казахстанский паспорт 2014 (оборотная сторона)",
            KYRGYZ_ID_2004_BACK:
              "Распознано: Паспорт гражданина Кыргызской Республики образца 2004 года (ID карта) (оборотная сторона)",
            KYRGYZ_ID_2004_FRONT:
              "Распознано: Паспорт гражданина Кыргызской Республики образца 2004 года (ID карта) (лицевая сторона)",
            KYRGYZ_ID_2017_BACK:
              "Распознано: Паспорт гражданина Кыргызской Республики образца 2017 года (ID карта) (оборотная сторона)",
            KYRGYZ_ID_2017_FRONT:
              "Распознано: Паспорт гражданина Кыргызской Республики образца 2017 года (ID карта) (лицевая сторона)",
            KYRGYZ_PASSPORT_2006_P30_P31:
              "Распознано: Общегражданский паспорт гражданина Кыргызской Республики образца 2006 года (30-31)",
            KYRGYZ_PASSPORT_2006_P31:
              "Распознано: Общегражданский паспорт гражданина Кыргызской Республики образца 2006 года (31)",
            KYRGYZ_PASSPORT_2006_OTHER:
              "Распознано: Общегражданский паспорт гражданина Кыргызской Республики образца 2006 года",
            RUSSIAN_PASSPORT_P2_P3: "Распознано: Российский паспорт (2-3)",
            RUSSIAN_PASSPORT_P2: "Распознано: Российский паспорт (2)",
            RUSSIAN_PASSPORT_P3: "Распознано: Российский паспорт (3)",
            RUSSIAN_SNILS_BACK:
              "Распознано: Страховое свидетельство обязательного (государственного) пенсионного страхования (СНИЛС в ламинированных и пластиковых карточках по формам АДИ-7/АДИ-1) (оборотная сторона)",
            RUSSIAN_SNILS_FRONT:
              "Распознано: Страховое свидетельство обязательного (государственного) пенсионного страхования (СНИЛС в ламинированных и пластиковых карточках по формам АДИ-7/АДИ-1) (лицевая сторона)",
            DRIVER_LICENSE_FRONT:
              "Распознано: Водительское удостоверение (лицевая сторона)",
            DRIVER_LICENSE_BACK:
              "Распознано: Водительское удостоверение (оборотная сторона)",
            VEHICLE_REGISTRATION_FRONT:
              "Распознано: Свидетельство о регистрации транспортного средства (лицевая сторона)",
            VEHICLE_REGISTRATION_BACK:
              "Распознано: Свидетельство о регистрации транспортного средства (оборотная сторона)",
            unknownDocType: "Тип не распознан",
          },
          "bottom-section": {
            text: "Технологии компьютерного зрения от ",
          },
          fields: {
            address_federal_subject: "Субъект РФ",
            address_settlement: "Населенный пункт",
            address_district: "Район",
            address_street: "Улица",
            address_street_number: "Дом",
            address_building_number: "Корпус (строение)",
            address_apartment_number: "Квартира",
            authority: "Орган, выдавший документ",
            birth_date: "Дата рождения",
            birth_date_check_digit: "Контрольная цифра даты рождения",
            birthplace: "Место рождения",
            birthplace_ru: "Место рождения (Русский)",
            birthplace_en: "Место рождения (Латиница)",
            category: "Категория",
            category_a: "Категория A",
            category_b: "Категория B",
            category_c: "Категория C",
            category_d: "Категория D",
            category_e: "Категория E",
            citizenship: "Гражданство",
            citizenship_en: "Гражданство (Латиница)",
            code: "Код",
            composite_check_digit: "Заключительная контрольная цифра",
            document_code: "Тип",
            document_type_issuing_state_code:
              "Тип документа и государство выдачи",
            expiry_date: "Дата истечения срока действия",
            expiry_date_check_digit:
              "Контрольная цифра даты истечения срока действия",
            extra_data_check_digit:
              "Контрольная цифра дополнительных элементов данных",
            first_and_middle_name: "Имя и отчество",
            first_middle_name: "Имя и отчество",
            first_name: "Имя",
            first_name_en: "Имя (Латиница)",
            first_name_ru: "Имя (Русский)",
            first_name_latin: "Имя (Латиница)",
            gender: "Пол",
            is_grayscale: "Черно-белый",
            is_handwritten: "Рукописный",
            issue_date: "Дата выдачи",
            issue_place: "Место выдачи",
            issuer: "Паспорт выдан",
            issuer_ru: "Паспорт выдан (Русский)",
            issuer_en: "Паспорт выдан (Латиница)",
            issuer_code: "Код подразделения",
            issuing_state_code: "Код государства выдачи",
            last_name: "Фамилия",
            last_name_en: "Фамилия (Латиница)",
            last_name_ru: "Фамилия (Русский)",
            last_name_latin: "Фамилия (Латиница)",
            marital_status: "Семейное положение",
            middle_name: "Отчество",
            middle_name_ru: "Отчество (Русский)",
            mrz: "Машиночитаемая зона",
            nationality: "Национальность",
            passport_number_check_digit: "Контрольная цифра серии и номера",
            personal_number: "Идентификационный номер",
            photo: "Фото",
            serial_number: "Серия и номер",
            serial_number_check_digit: "Контрольная цифра серии и номера",
            signature: "Подпись",
            special_marks: "Особые метки",
            symbol_on_position_42: "Символ в позиции 42",
            type_and_issue_state: "Тип документа и государство выдачи",
            registration_date: "Дата регистрации",
            regulations_compliance_number: "Одобрение типа ТС №",
            residence_place: "Место жительства",
            rf_sign: 'Имеет знак "РФ"',
            has_stamp: "Имеет штамп",
            valid_until: "Действительно до",
            vehicle_license_plate: "Регистрационный знак",
            vehicle_identification_number: "Идентификационный номер (VIN)",
            vehicle_brand: "Марка",
            vehicle_model: "Модель",
            vehicle_body_type: "Тип ТС",
            vehicle_category: "Категория ТС",
            vehicle_manufacture_year: "Год выпуска ТС",
            vehicle_chassis_number: "Шасси (рама) №",
            vehicle_body_number: "Кузов (кабина, прицеп) №",
            vehicle_color: "Цвет",
            vehicle_engine_power: "Мощность двигателя, кВт/л.с.",
            vehicle_emissions_class: "Экологический класс",
            vehicle_certificate_number: "Паспорт ТС №",
            vehicle_gross_combined_weight_rating:
              "Технически допустимая макс. масса, кг",
            vehicle_curb_weight: "Масса в снаряженном состоянии",
            unit_code: "Код подразделения",
            unit_name: "Выдан",
            custom_names: {
              RUSSIAN_SNILS_FRONT: {
                personal_number: "Страховой номер",
              },
              RUSSIAN_SNILS_ADIREG: {
                personal_number: "Страховой номер",
              },
              KYRGYZ_ID_2004_BACK: {
                expiry_date:
                  "Жарактуу мɵɵнɵтү бүткɵн күнү/Дата окончания срока действия",
                issue_date: "Берилген күнү/Дата выдачи",
                issuer: "Документти берген мекеме/Орган, выдавший документ",
                marital_status: "Үй-бүлɵсү/Семейное положение",
                residence_place: "Жашаган жери/Место жительства",
              },
              KYRGYZ_ID_2004_FRONT: {
                last_name: "Аты-жɵнү/Фамилия",
                first_name: "Имя",
                middle_name: "Отчество",
                birth_date: "Туулган күнү/Дата рождения",
                gender: "Жынысы/Пол",
                birthplace: "Туулган жери/Место рождения",
                serial_number: "Документтин №",
                nationality: "Улуту/Национальность",
                personal_number: "Жеке номуру/Персональный номер",
              },
              KYRGYZ_ID_2017_BACK: {
                birthplace: "Туулган жери/Место рождения/Place of birth",
                issuer: "Берген мекеме/Орган выдачи/Authority",
                issue_date: "Берилген куну/Дата выдачи/Date of issue",
                personal_number:
                  "Жеке номуру/Персональный номер/Personal number",
                serial_number: "Номер документа",
              },
              KYRGYZ_ID_2017_FRONT: {
                last_name: "Фамилиясы/Фамилия/Surname",
                first_name: "Аты/Имя/Name",
                middle_name: "Атасынын аты/Отчество/Patronymic",
                birth_date: "Туулган күнү/Дата рождения/Date of birth",
                gender: "Жынысы/Пол/Sex",
                citizenship: "Жарандыгы/Гражданство/Nationality",
                serial_number: "Документтин №/№ документа/Document",
                expiry_date: "Колдонуу мɵɵнɵту/Срок действия/Date of expiry",
              },
              KYRGYZ_PASSPORT_2006_P30_P31: {
                document_type: "Түрү/Тип/Туре",
                issuing_state_code: "Өлкөнүн коду/Код страны/Country code",
                last_name: "Фамилиясы/Фамилия/Surname",
                first_name: "Аты/Имя/Given names",
                gender: "Жынысы/Пол/Sex",
                citizenship: "Жарандыгы/Гражданство/Citizenship",
                birth_date: "Туулган датасы/Дата рождения/Date of birth",
                birthplace: "Туулган жери/Место рождения/Place of birth",
                issue_date: "Берилген датасы/Дата выдачи/Date of issue",
                authority:
                  "Документти берген орган/Орган, выдавший документ/ Issuing",
                issuer:
                  "Документти берген орган/Орган, выдавший документ/ Issuing authority",
                date: "Жарактуу мөөнөтү бүткөн датасы/Дата окончания срока действия/ Expiry",
                expiry_date:
                  "Жарактуу мөөнөтү бүткөн датасы/Дата окончания срока действия/ Expiry date",
                serial_number: "Паспорттун №/Паспорт №/Passport №",
                personal_number:
                  "Жеке номери/Персональный номер/Personal number",
              },
              KYRGYZ_PASSPORT_2006_P31: {
                document_type: "Түрү/Тип/Туре",
                issuing_state_code: "Өлкөнүн коду/Код страны/Country code",
                last_name: "Фамилиясы/Фамилия/Surname",
                first_name: "Аты/Имя/Given names",
                gender: "Жынысы/Пол/Sex",
                citizenship: "Жарандыгы/Гражданство/Citizenship",
                birth_date: "Туулган датасы/Дата рождения/Date of birth",
                birthplace: "Туулган жери/Место рождения/Place of birth",
                issue_date: "Берилген датасы/Дата выдачи/Date of issue",
                authority:
                  "Документти берген орган/Орган, выдавший документ/ Issuing",
                issuer:
                  "Документти берген орган/Орган, выдавший документ/ Issuing authority",
                date: "Жарактуу мөөнөтү бүткөн датасы/Дата окончания срока действия/ Expiry",
                expiry_date:
                  "Жарактуу мөөнөтү бүткөн датасы/Дата окончания срока действия/ Expiry date",
                serial_number: "Паспорттун №/Паспорт №/Passport №",
                personal_number:
                  "Жеке номери/Персональный номер/Personal number",
              },
            },
          },
          language: {
            switch: "EN",
          },
          photo: {
            connecting: "Подключение камеры...",
            make_photo: "Сделать фото с камеры",
            error: "Ошибка подключения камеры",
          },
          demo: {
            name: "Распознавание документов",
            description_1:
              "Модуль распознавания документов Tevian Doc SDK позволяет извлекать содержимое из структурированных документов. В совокупности с широкими возможностями по интеграции данная технология позволит оцифровать, ускорить и автоматизировать многие процессы, связанные с документооборотом. Загрузите один из поддерживаемых документов и оцените качество распознавания текста и классификации полей.",
            description_2: {
              title: "Список поддерживаемых документов:",
              passports_title: "Паспорта:",
              russian: "Российской Федерации",
              belarusian: "Республики Беларусь",
              kyrgyz: "Республики Кыргызстан",
              kazahstan: "Республики Казахстан",
              id_cards_title: "ID карты:",
              snils_rf: "СНИЛС РФ",
              driver_id_rf: "Водительское удостоверение РФ",
              insurance_rf:
                "Свидетельство о регистрации транспортного средства РФ",
            },
          },
          policy: "Политика конфиденциальности",
          titles: {
            mrz_warnings: "Ошибки парсинга документа",
            mrz_fields: "Поля МЧЗ",
            text_unsupported:
              "На данном развороте документа не поддерживается распознавание полей",
            unable_to_display:
              "Отображение документа данного типа невозможно в демо-интерфейсе при загрузке PDF",
            global_warnings: "Глобальные предупреждения по документу",
            detected_stamps: "Обнаруженные штампы",
          },
        },
      },
    },
  });
