import { FC, HTMLProps } from "react";
import styles from "./Chip.module.scss";
import classNames from "classnames";

export type Props = {
  label: string;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Chip: FC<Props> = (props) => {
  const { label, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <span>{label}</span>
    </div>
  );
};

export default Chip;
