import { FC, HTMLProps, useEffect, useState } from "react";
import styles from "./Preview.module.scss";
import classNames from "classnames";
import useFile from "../../utils/hooks/useFile";
import { fileIsImage, setImageParams } from "../../utils/functions";
import PDF from "../../assets/img/pdf-file.svg";
import useNotify from "../../utils/hooks/useNotify";
import { useDispatch } from "../../store/hooks";
import { setAppState } from "../../store/reducers/globalSlice";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Preview: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames([
    styles["container"],
    addClasses && [...addClasses],
  ]);
  const { fileSrc, name } = useFile();
  const { notify } = useNotify();
  const dispatch = useDispatch();

  const [imgSize, setImgSize] = useState({ w: 0, h: 0 });

  const isImage = fileIsImage(fileSrc, name);

  useEffect(() => {
    if (isImage) {
      setImageParams(
        fileSrc,
        (img) => {
          setImgSize({
            w: img.width < 3000 ? img.width : 3000,
            h: img.height,
          });
        },
        () => {
          notify("error", "notification.errorImageLoad");
          dispatch(setAppState("initial"));
        }
      );
    }
  }, [fileSrc, isImage, dispatch, notify]);

  return (
    <div className={className} {...props}>
      <svg className={styles["img"]} viewBox={`0 0 ${imgSize.w} ${imgSize.h}`}>
        <image href={fileSrc} width={imgSize.w} height={imgSize.h} />
      </svg>
    </div>
  );
};

export default Preview;
