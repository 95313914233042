import { configureStore } from "@reduxjs/toolkit";
import fileReducer from "./reducers/fileSlice";
import resultReducer from "./reducers/resultSlice";
import notificationReducer from "./reducers/notificationSlice";

import appStateReducer from "./reducers/globalSlice";

export const store = configureStore({
  reducer: {
    appState: appStateReducer,
    file: fileReducer,
    result: resultReducer,
    notif: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
