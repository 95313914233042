import { createSlice } from "@reduxjs/toolkit";

interface FileState {
  fileSrc: string;
  name: string;
  fileExtension: string;
}

const initialState: FileState = {
  fileSrc: "",
  name: "",
  fileExtension: "",
};

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFileSrc: (state, action) => {
      state.fileSrc = action.payload;
    },
    setFileName: (state, action) => {
      state.name = action.payload;
    },
    setFileExtension: (state, action) => {
      state.fileExtension = action.payload;
    },
  },
});

export const { setFileSrc, setFileName, setFileExtension } = fileSlice.actions;

export default fileSlice.reducer;
