import { useContext } from "react";
import { ConfirmContext } from "../../components/Warning/ConfirmContext";

const useConfirm = () => {
  const { confirm, setConfirm } = useContext(ConfirmContext);
  const isConfirmed = () => {
    setConfirm({ ...confirm, isOpen: true });
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        userAlreadyAgreed: false,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });
    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false, userAlreadyAgreed: true });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false, userAlreadyAgreed: false });
        return false;
      }
    );
  };
  return { ...confirm, isConfirmed };
};

export default useConfirm;
