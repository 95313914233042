import { FC, HTMLProps } from "react";
import styles from "./GlobalWarnings.module.scss";
import classNames from "classnames";
import GlobalWarningCard from "../../../components/GlobalWarningCard";
import { useTranslation } from "react-i18next";
import { Document } from "../../../types";
import { formatWarnings } from "./utils";

export type Props = {
  document: Document;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const GlobalWarnings: FC<Props> = (props) => {
  const { document, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { t } = useTranslation();

  if (!document.global_warnings?.length) return null;
  const formatted = formatWarnings(document, t);
  return (
    <div className={className} {...props}>
      <h2>{t("titles.global_warnings")}</h2>
      {formatted.map((w) => (
        <GlobalWarningCard title={w} warnings={[]} />
      ))}
    </div>
  );
};

export default GlobalWarnings;
