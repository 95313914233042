import React, { createContext, useState } from "react";

export const ConfirmContext = createContext({
  confirm: {
    isOpen: false,
    proceed: null,
    cancel: null,
    userAlreadyAgreed: false,
  },
  setConfirm: (prev: any) => prev,
});

const ConfirmContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirm, setConfirm] = useState({
    isOpen: false,
    proceed: null,
    cancel: null,
    userAlreadyAgreed: false,
  });

  return (
    <ConfirmContext.Provider value={{ confirm, setConfirm }}>
      {children}
    </ConfirmContext.Provider>
  );
};
export default ConfirmContextProvider;
