import { FC, HTMLProps } from "react";
import styles from "./Pagination.module.scss";
import classNames from "classnames";
import { ReactComponent as NextIcon } from "./arrowNext.svg";
import { ReactComponent as PrevIcon } from "./arrowBack.svg";

export type Props = {
  next: () => void;
  prev: () => void;
  sign: string;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Pagination: FC<Props> = (props) => {
  const { next, prev, sign, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  return (
    <div className={className} {...props}>
      <PrevIcon onClick={next} />
      <span>{sign}</span>
      <NextIcon onClick={prev} />
    </div>
  );
};

export default Pagination;
