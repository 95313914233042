// v1.0.2
import { FC, HTMLProps, useEffect, useRef } from "react";
import styles from "./Description.module.scss";
import classNames from "classnames";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

export type Props = {
  opened: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Description: FC<Props> = (props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { opened, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["closed"]]: !opened }
  );

  const closeDescription = () => {
    gsap.to(containerRef.current, {
      height: 0,
    });
  };

  const openDescription = () => {
    gsap.to(containerRef.current, {
      height: "auto",
    });
  };

  useEffect(() => {
    if (opened) {
      openDescription();
    } else {
      closeDescription();
    }
  }, [opened]);

  return (
    <div className={className} {...props} ref={containerRef}>
      <div className={styles["text-container"]}>
        <span>{t("demo.description_1")}</span>
        <br />
        <br />
        <span>{t("demo.description_2.title")}</span>
        <ul className={styles["parent-list"]}>
          <li>
            {t("demo.description_2.passports_title")}
            <ul>
              <li>{t("demo.description_2.russian")}</li>
              <li>{t("demo.description_2.belarusian")}</li>
              <li>{t("demo.description_2.kyrgyz")}</li>
            </ul>
          </li>
          <li>
            <span>{t("demo.description_2.id_cards_title")}</span>
            <ul>
              <li>{t("demo.description_2.kazahstan")}</li>
              <li>{t("demo.description_2.kyrgyz")}</li>
            </ul>
          </li>
          <li>{t("demo.description_2.snils_rf")}</li>
          <li>{t("demo.description_2.driver_id_rf")}</li>
          <li>{t("demo.description_2.insurance_rf")}</li>
        </ul>
      </div>
    </div>
  );
};

export default Description;
