import { Field, Document } from "../types";
import { order } from "./constants";

const imageFormats = ["png", "jpg", "jpeg", "svg"];

export type Lang = "ru" | "en";

export function isLang(lang: string | null): lang is Lang {
  return lang === "ru" || lang === "en";
}

export const getFileExtension = (file_src: string) => {
  return file_src.split(".")[file_src.split(".").length - 1];
};

export const fileIsImage = (file_src: string, file_name: string): boolean => {
  return (
    imageFormats.includes(getFileExtension(file_src)) ||
    imageFormats.includes(getFileExtension(file_name)) ||
    file_src.includes("data:image")
  );
};

export const splitPassportSerialNumber = (number: string): string => {
  const { length } = number;
  let result = "";
  const breakpoints = [2, 5];
  for (let i = 0; i < length; i++) {
    result += number[i];
    if (breakpoints.includes(result.length)) {
      result += " ";
    }
  }
  return result;
};

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export function isMobileDevice() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      ) ||
      (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform))
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

export const setImageParams = (
  src: string,
  cb: (img: HTMLImageElement) => void,
  errorCb: () => void
) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onload = () => {
      cb(img);
    };
    img.onerror = (e) => {
      errorCb();
      throw new Error(e.toString());
    };
  });
};

export const documentToFieldArray = (doc: Document) => {
  type NamedField = {
    name: string;
  } & Field;

  // get document type
  const mostConfident = Object.entries(doc.types).sort(
    ([_, c1], [n1, c2]) => c2 - c1
  )[0];
  const [type] = mostConfident;

  // get order of fields of specified document
  const docOrder = order[type];

  const fields = Object.entries(doc.fields || {}).map(([name, val]) => {
    return { ...val, name };
  });

  // add fields with REQUIRED_FIELD_IS_MISSING warning
  const missingRequiredFields = Object.entries(
    doc.missing_fields_warnings || {}
  ).reduce((acc, [name, warnings]) => {
    if (warnings.includes("REQUIRED_FIELD_IS_MISSING")) {
      const field = {
        overall_score: 1,
        position: { x: 0, y: 0, h: 0, w: 0 },
        scores: [1],
        text: "",
        warnings: ["REQUIRED_FIELD_IS_MISSING"],
        name,
      };
      acc.push(field);
    }
    return acc;
  }, [] as NamedField[]);

  // order fields by specified order
  const allFields = [...fields, ...missingRequiredFields].sort(
    (a, b) => docOrder[a.name] - docOrder[b.name]
  );
  return allFields as NamedField[];
};

export const imgToBlob = async (url: string) => {
  try {
    const res = await new Promise((res, rej) => {
      const image = new Image();
      image.onload = async () => {
        const { width, height } = image;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        const blob = await new Promise((res, rej) => {
          canvas.toBlob((blob) => {
            res(blob);
            rej((e: any) => {
              throw new Error(e);
            });
          }, "image/jpeg");
        });
        res(blob);
        rej((e: any) => {
          throw new Error(e);
        });
      };
      image.onerror = () => {
        rej((e: any) => {
          throw new Error(e);
        });
      };
      image.src = url;
    });
    return res as Promise<Blob>;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
