import { createSlice } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import { AlertColor } from "@mui/material";

type MessageType = {
  type: AlertColor;
  message: string;
  show: boolean;
};

const initialState: MessageType = {
  type: "info",
  message: "",
  show: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotif: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.show = true;
    },
    showNotif: (state, payload) => {
      state.show = payload.payload;
    },
  },
});

export const setNoDevices = () => async (dispatch: AppDispatch) => {
  dispatch(setNotif({ message: "Camera has not been found!", type: "error" }));
};

export const { setNotif, showNotif } = notificationSlice.actions;

export default notificationSlice.reducer;
