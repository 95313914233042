import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./utils/i18n";
import axios from "axios";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

declare global {
  interface Window {
    API_URL: string;
    YANDEX_METRIC_ID: string;
    DOCUMENT_TYPE_MIN_CONFIDENCE: number;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
    IMAGE_VERSION: string;
    BUILD_VERSION: string;
    SHOW_VERSION_INFO: string;
  }
}

const {
  SENTRY_DSN,
  SENTRY_TRACES_RATE,
  SENTRY_ENVIRONMENT,
  IMAGE_VERSION,
  BUILD_VERSION,
  API_URL,
} = window;

axios.defaults.baseURL = API_URL;

console.log(IMAGE_VERSION, BUILD_VERSION);

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./Tests/api/browser");
  return worker.start();
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

enableMocking().then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  )
);
