import { createSlice } from "@reduxjs/toolkit";

type State = "initial" | "request" | "result";

const initialState: { currentState: State } = {
  currentState: "initial",
};

export const globalSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: { type: string; payload: State }) => {
      state.currentState = action.payload;
    },
  },
});

export const { setAppState } = globalSlice.actions;

export default globalSlice.reducer;
