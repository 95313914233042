import { FC, HTMLProps, useEffect, useMemo, useState } from "react";
import styles from "./InitialScreen.module.scss";
import classNames from "classnames";
import MakePhoto from "../../components/MakePhoto";
import { Button } from "@mui/material";
import { useDispatch } from "../../store/hooks";
import { clearRes } from "../../store/reducers/resultSlice";
import { setFileSrc } from "../../store/reducers/fileSlice";
import useFile from "../../utils/hooks/useFile";
import useDragAndDrop from "../../components/Dropzone/useDragAndDrop";
import { useTranslation } from "react-i18next";
import Dropzone from "../../components/Dropzone";
import { isMobileDevice } from "../../utils/functions";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const DND_FILE_LIMIT = 1;
const DND_ALLOWED_TYPES = ["jpg", "jpeg", "heic", "png", "pdf"];

const InitialScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const dispatch = useDispatch();
  const [showCamera, setShowCamera] = useState(false);
  const { handleFileDrop } = useFile();
  const {
    isDragging,
    isError,
    currentState,
    onDragEnter,
    onDragLeave,
    onDrop,
  } = useDragAndDrop(DND_ALLOWED_TYPES, DND_FILE_LIMIT, handleFileDrop);
  const { t } = useTranslation();

  const dndMsg = useMemo(() => {
    switch (currentState) {
      case "file_limit_exceeded":
        return t(`dragAndDrop.${currentState}`, { limit: DND_FILE_LIMIT });
      case "type_not_allowed":
        return t(`dragAndDrop.${currentState}`, {
          types: DND_ALLOWED_TYPES.join(","),
        });
      default:
        return t(`dragAndDrop.${currentState}`);
    }
  }, [t, currentState]);

  const handleClick = () => {
    setShowCamera(true);
  };

  useEffect(() => {
    dispatch(clearRes());
    dispatch(setFileSrc(""));
    return () => {
      setShowCamera(false);
    };
  }, []);

  return (
    <div className={className} {...props}>
      {showCamera ? (
        <MakePhoto />
      ) : (
        <>
          <Dropzone
            onUpload={handleFileDrop}
            isDragging={isDragging}
            isError={isError}
            msg={dndMsg}
            accept={["image/*", "application/pdf"] as any}
            onDragEnter={onDragEnter as any}
            onDragLeave={onDragLeave as any}
            onDrop={onDrop}
          />
          {!isMobileDevice() && (
            <Button onClick={handleClick}>{t("photo.make_photo")}</Button>
          )}
        </>
      )}
    </div>
  );
};

export default InitialScreen;
