import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useConfirm from "../../utils/hooks/useConfirm";

const Warning = () => {
  const { t } = useTranslation();
  const { isOpen, proceed, cancel } = useConfirm();
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("warning.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("warning.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={proceed as any}>
          Ok
        </Button>
        <Button variant="contained" onClick={cancel as any}>
          {t("warning.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Warning;
