export const order: { [key: string]: { [key: string]: number } } = {
  // PASSPORT
  BELARUSIAN_PASSPORT_NOTES: {},
  BELARUSIAN_PASSPORT_P30_P31: {
    last_name: 0,
    last_name_ru: 1,
    first_name: 2,
    first_name_ru: 3,
    middle_name: 4,
    middle_name_ru: 5,
    birth_date: 6,
    personal_number: 7,
    birthplace: 8,
    birthplace_ru: 9,
    issue_date: 10,
    expiry_date: 11,
    issuer: 12,
    issuer_ru: 13,
  },
  BELARUSIAN_PASSPORT_P32_P33: {
    document_code: 0,
    issuing_state_code: 1,
    serial_number: 2,
    last_name_en: 3,
    first_name_en: 4,
    nationality: 5,
    citizenship_en: 6,
    birth_date: 7,
    personal_number: 8,
    gender: 9,
    birthplace_en: 10,
    issue_date: 11,
    issuer_en: 12,
    expiry_date: 13,
  },
  BELARUSIAN_PASSPORT_P32: {},
  BELARUSIAN_PASSPORT_P33: {
    document_type: 0,
    issuing_state_code: 1,
    serial_number: 2,
    last_name_en: 3,
    first_name_en: 4,
    nationality: 5,
    citizenship_en: 6,
    personal_number: 7,
    gender: 8,
    birthplace_en: 9,
    issue_date: 10,
    issuer_en: 11,
    expiry_date: 12,
  },
  RUSSIAN_PASSPORT_P2_P3: {
    serial_number: 0,
    issuer: 1,
    issue_date: 2,
    issuer_code: 3,
    signature: 4,
    photo: 5,
    last_name: 6,
    first_name: 7,
    middle_name: 8,
    gender: 9,
    birth_date: 10,
    birthplace: 11,
    mrz: 12,
  },
  RUSSIAN_PASSPORT_P2: {
    serial_number: 0,
    issuer: 1,
    issue_date: 2,
    issuer_code: 3,
  },
  RUSSIAN_PASSPORT_P3: {
    last_name: 0,
    first_name: 1,
    middle_name: 2,
    gender: 3,
    birth_date: 4,
    birthplace: 5,
    serial_number: 6,
  },
  KAZAKHSTAN_ID_1994_FRONT: {},
  KYRGYZ_ID_2004_FRONT: {
    last_name: 0,
    first_name: 1,
    middle_name: 2,
    birth_date: 3,
    gender: 4,
    birthplace: 5,
    serial_number: 6,
    nationality: 7,
    personal_number: 8,
  },
  KYRGYZ_ID_2004_BACK: {
    marital_status: 0,
    residence_place: 1,
    issue_date: 2,
    issue: 3,
    expiry_date: 4,
  },
  KYRGYZ_ID_2017_FRONT: {
    last_name: 0,
    first_name: 1,
    middle_name: 2,
    gender: 3,
    citizenship: 4,
    birth_date: 5,
    serial_number: 6,
    expiry_date: 7,
  },
  KYRGYZ_ID_2017_BACK: {
    birthplace: 0,
    issuer: 1,
    issue_date: 2,
    personal_number: 3,
    serial_number: 4,
  },
  KYRGYZ_PASSPORT_2006_P30_P31: {
    document_type: 0,
    issuing_state_code: 1,
    last_name: 2,
    first_name: 3,
    gender: 4,
    citizenship: 5,
    serial_number: 6,
    birth_date: 7,
    birthplace: 8,
    issue_date: 9,
    authority: 10,
    issuer: 11,
    date: 12,
    personal_number: 13,
    expiry_date: 14,
  },
  KYRGYZ_PASSPORT_2006_P31: {
    document_type: 0,
    issuing_state_code: 1,
    last_name: 2,
    first_name: 3,
    gender: 4,
    nationality: 5,
    serial_number: 6,
    birth_date: 7,
    citizenship: 8,
    birthplace: 9,
    issue_date: 10,
    authority: 11,
    issuer: 12,
    date: 13,
    personal_number: 14,
    expiry_date: 15,
  },
  KYRGYZ_PASSPORT_2006_OTHER: {},
  KAZAKHSTAN_ID_2008_FRONT: {
    last_name: 0,
    first_name: 1,
    middle_name: 2,
    birth_date: 3,
    personal_number: 4,
  },
  KAZAKHSTAN_ID_2008_BACK: {
    serial_number: 0,
    birthplace: 1,
    nationality: 2,
    issuer: 3,
    issue_date: 4,
    expiry_date: 5,
  },
  KAZAKHSTAN_ID_2014_FRONT: {
    last_name: 0,
    first_name: 1,
    middle_name: 2,
    birth_date: 3,
    personal_number: 4,
  },
  KAZAKHSTAN_ID_2014_BACK: {
    serial_number: 0,
    birthplace: 1,
    nationality: 2,
    issuer: 3,
    issue_date: 4,
    expiry_date: 5,
  },
  // Driver licenses
  DRIVER_LICENSE_FRONT: {
    last_name: 0,
    first_middle_name: 1,
    birth_date: 2,
    birthplace: 3,
    issue_date: 4,
    valid_until: 5,
    unit_name: 6,
    serial_number: 7,
    issue_place: 8,
    category: 9,
  },
  DRIVER_LICENSE_BACK: {
    category: 0,
    special_marks: 1,
    code: 2,
    serial_number: 3,
  },
  VEHICLE_REGISTRATION_FRONT: {
    vehicle_license_plate: 0,
    vehicle_identification_number: 1,
    vehicle_brand: 2,
    vehicle_model: 3,
    vehicle_body_type: 4,
    vehicle_category: 5,
    vehicle_manufacture_year: 6,
    vehicle_chassis_number: 7,
    vehicle_body_number: 8,
    vehicle_color: 9,
    vehicle_engine_power: 10,
    regulations_compliance_number: 11,
    vehicle_emissions_class: 12,
    vehicle_certificate_number: 13,
    vehicle_gross_combined_weight_rating: 14,
    vehicle_curb_weight: 15,
    valid_until: 16,
    serial_number: 17,
  },
  VEHICLE_REGISTRATION_BACK: {
    serial_number: 0,
    last_name: 1,
    last_name_latin: 2,
    first_name: 3,
    first_name_latin: 4,
    middle_name: 5,
    address_federal_subject: 6,
    address_settlement: 7,
    address_district: 8,
    address_street: 9,
    address_street_number: 10,
    address_building_number: 11,
    address_apartment_number: 12,
    special_marks: 13,
    unit_code: 14,
    issue_date: 15,
  },
  RUSSIAN_SNILS_FRONT: {
    personal_number: 0,
    last_name: 1,
    first_name: 2,
    middle_name: 3,
    birth_date: 4,
    birthplace: 5,
    gender: 6,
    registration_date: 7,
  },
  RUSSIAN_SNILS_ADIREG: {
    personal_number: 0,
    last_name: 1,
    first_name: 2,
    middle_name: 3,
    birth_date: 4,
    birthplace: 5,
    gender: 6,
    registration_date: 7,
  },
};

export const hasNoTextDetection = {
  BELARUSIAN_PASSPORT_NOTES: true,
  BELARUSIAN_PASSPORT_P32: true,
  KAZAKHSTAN_ID_1994_FRONT: true,
  RUSSIAN_SNILS_BACK: true,
  KYRGYZ_PASSPORT_2006_OTHER: true,
};
