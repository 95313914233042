import axios from "axios";
import { useDispatch } from "../../store/hooks";
import { setRes } from "../../store/reducers/resultSlice";
import useNotify from "./useNotify";
import { useState } from "react";
import { setAppState } from "../../store/reducers/globalSlice";

declare global {
  interface Window {
    API_URL: string;
    YANDEX_METRIC_ID: string;
  }
}

const url = window.API_URL;
const docTypes = [
  "BELARUSIAN_PASSPORT_NOTES",
  "BELARUSIAN_PASSPORT_P30_P31",
  "BELARUSIAN_PASSPORT_P32_P33",
  "BELARUSIAN_PASSPORT_P32",
  "BELARUSIAN_PASSPORT_P33",
  "RUSSIAN_PASSPORT_P2_P3",
  "RUSSIAN_PASSPORT_P2",
  "RUSSIAN_PASSPORT_P3",
  "KAZAKHSTAN_ID_1994_FRONT",
  "KAZAKHSTAN_ID_2008_FRONT",
  "KAZAKHSTAN_ID_2008_BACK",
  "KAZAKHSTAN_ID_2014_FRONT",
  "KAZAKHSTAN_ID_2014_BACK",
  "KYRGYZ_ID_2004_FRONT",
  "KYRGYZ_ID_2004_BACK",
  "KYRGYZ_PASSPORT_2006_P30_P31",
  "KYRGYZ_PASSPORT_2006_P31",
  "KYRGYZ_PASSPORT_2006_OTHER",
  "KYRGYZ_ID_2017_FRONT",
  "KYRGYZ_ID_2017_BACK",
  "DRIVER_LICENSE_FRONT",
  "DRIVER_LICENSE_BACK",
  "VEHICLE_REGISTRATION_FRONT",
  "VEHICLE_REGISTRATION_BACK",
  "RUSSIAN_SNILS_FRONT",
  "RUSSIAN_SNILS_BACK",
];

const useRequest = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { notify } = useNotify();

  const makeRequest = async (fileSrc: string, debug?: boolean) => {
    setLoading(true);
    try {
      let image = await fetch(fileSrc).then((r) => r.blob());
      const res = await axios({
        method: "post",
        url: "/document/recognize",
        headers: {
          Debug: debug ? "True" : "False",
        },
        params: {
          types: docTypes.join(","),
          min_detection_score: "0.7",
          ocr: "all",
          with_mrz: "true",
          with_is_grayscale: "true",
          with_rf_sign: "true",
          with_stamp_detector: "true",
          with_predicted_doc_age: "true",
        },
        data: image,
      });
      // handleDocChange(res.data.documents[0]);
      dispatch(setRes(res.data.documents));
      if (!res.data.documents.length) {
        notify("error", "nothingDetected");
        setError(true);
      } else {
        dispatch(setAppState("result"));
      }
    } catch (err: any) {
      if (err.response) {
        const message = err.response.data.code
          ? err.response.data.code
          : "wentWrong";
        console.error(message);
        console.error(err.response.data.message);
        notify("error", message);
        setError(true);
        return;
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    makeRequest,
    error,
  };
};

export default useRequest;
