import { FC, HTMLProps, useLayoutEffect, useState } from "react";
import styles from "./Field.module.scss";
import classNames from "classnames";
import { ReactComponent as CopyIcon } from "../../assets/imgs/copy.svg";
import { ReactComponent as CheckIcon } from "../../assets/imgs/check.svg";
import gsap from "gsap";
import Chip from "../Chip";

export type Props = {
  name: string;
  textValue: string;
  warnings: string[];
  selected?: boolean;
  onCopy?: () => void;
  Image?: any;
  vstack?: boolean;
  fillOnHover?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Field: FC<Props> = (props) => {
  const {
    onCopy,
    name,
    warnings,
    textValue,
    selected,
    addClasses,
    Image,
    vstack,
    fillOnHover,
  } = props;

  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["selected"]]: selected, [styles["fill-on-hover"]]: fillOnHover }
  );

  const contentClass = classNames([styles["content-container"]], {
    [styles["vstack"]]: vstack,
  });

  const iconContainerClass = classNames([styles["icons-container"]], {
    [styles["invisible"]]: (!selected && !textValue) || Image,
  });

  const [toggle, setToggle] = useState(true);

  const copyField = () => {
    setToggle(false);
    onCopy && onCopy();
    setTimeout(() => {
      setToggle(true);
    }, 1000);
  };

  useLayoutEffect(() => {
    gsap.fromTo(
      `.${styles["warnings"]}`,
      { opacity: 0 },
      { opacity: 1, delay: 1 }
    );
  }, []);

  return (
    <div className={className} {...props}>
      <div className={contentClass}>
        <span className={styles["field-name"]}>{name}</span>
        {Image ? (
          Image
        ) : (
          <span className={styles["field-value"]}>{textValue}</span>
        )}
      </div>
      <div className={iconContainerClass}>
        {toggle ? <CopyIcon onClick={copyField} /> : <CheckIcon />}
      </div>
      {warnings.length ? (
        <div className={styles["warnings"]}>
          {warnings.map((w) => {
            return <Chip label={w} />;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Field;
