import { createSlice } from "@reduxjs/toolkit";
import { Response } from "../../types";

type Init = {
  documents: Response;
};

const initialState: Init = {
  documents: null,
};

export const resultSlice = createSlice({
  name: "res",
  initialState,
  reducers: {
    setRes: (state, payload) => {
      state.documents = payload.payload;
    },
    clearRes: (state) => {
      state.documents = null;
    },
  },
});

export const { setRes, clearRes } = resultSlice.actions;

export default resultSlice.reducer;
