import { useDispatch } from "react-redux";
import { setNotif } from "../../store/reducers/notificationSlice";
import { AlertColor } from "@mui/material";

export type NotifyType = (type: AlertColor, message: string) => void;

// создает функцию уведомлений, которая будет передаваться в spoofme
const useNotify = () => {
  const dispatch = useDispatch();
  const notify: NotifyType = (type, message) => {
    dispatch(setNotif({ type, message }));
  };

  return {
    notify,
  };
};

export default useNotify;
