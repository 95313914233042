import { FC, HTMLProps, useEffect, useRef, useState } from "react";
import styles from "./MakePhoto.module.scss";
import classNames from "classnames";
import useConfirm from "../../utils/hooks/useConfirm";
import useNotify from "../../utils/hooks/useNotify";
import { useDispatch } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Spinner from "../Spinner";
import { setFileSrc } from "../../store/reducers/fileSlice";
import { setAppState } from "../../store/reducers/globalSlice";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const MakePhoto: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const [cameraLoading, setCameraLoading] = useState(false);
  const [error, setError] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { t } = useTranslation();
  const { notify } = useNotify();
  const dispatch = useDispatch();
  const { isConfirmed, userAlreadyAgreed } = useConfirm();

  const videoClass = classNames([styles["video"]], {
    [styles["hide"]]: cameraLoading,
  });

  const loadCameraImg = async () => {
    setCameraLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          frameRate: 30,
          width: 1920,
          height: 1080,
        },
      });
      const video = videoRef.current!;
      const canvas = canvasRef.current!;
      if (!video.srcObject) {
        video.srcObject = stream;
      }
      let {
        videoWidth: viewWidth,
        videoHeight: viewHeight,
      }: { videoWidth: number; videoHeight: number } = await new Promise(
        (resolve, reject) => {
          video.onloadedmetadata = (e: Event) => {
            video.play();
            resolve(video);
          };
        }
      );
      canvas.width = viewWidth;
      canvas.height = viewHeight;
    } catch (e) {
      notify("error", "photo.error");
      setError(true);
    } finally {
      setCameraLoading(false);
    }
  };

  const handlePhoto = async () => {
    if (!userAlreadyAgreed) {
      const confirmed = await isConfirmed();
      if (!confirmed) return;
    }
    const canvas = canvasRef.current!;
    const video = videoRef.current!;
    canvas
      .getContext("2d")!
      .drawImage(video, 0, 0, canvas.width, canvas.height);
    let image_data_url = canvas.toDataURL("image/jpeg");
    dispatch(setFileSrc(image_data_url));
    dispatch(setAppState("request"));
  };

  useEffect(() => {
    loadCameraImg();
  }, []);

  return (
    <div className={className} {...props}>
      {cameraLoading && (
        <div className={styles["spinner-container"]}>
          <Spinner addClasses={[styles["spinner"]]} />
          <span>{t("photo.connecting")}</span>
        </div>
      )}
      <video ref={videoRef} className={videoClass} />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <Button
        variant="contained"
        onClick={handlePhoto}
        disabled={cameraLoading || error}
      >
        <PhotoCameraIcon />
      </Button>
    </div>
  );
};

export default MakePhoto;
