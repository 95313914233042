import { Document } from "../../../types";
export const formatWarnings = (doc: Document, translate: any) => {
  const formattedWarnings = doc.global_warnings!.map((w) => {
    switch (true) {
      case w.includes("PREDICTED_DOC_AGE_IS"):
        const { doc_age } = doc;
        const { predicted, real } = doc_age!;
        return translate(`warnings.${w}`, {
          predicted: predicted.toFixed(2),
          real: real.toFixed(2),
        });
      default: {
        return translate(`warnings.${w}`);
      }
    }
  });
  return formattedWarnings;
};
