import { useDispatch, useSelector } from "../../store/hooks";
import {
  setFileExtension,
  setFileName,
  setFileSrc,
} from "../../store/reducers/fileSlice";
import useConfirm from "./useConfirm";
import { setAppState } from "../../store/reducers/globalSlice";

const useFile = () => {
  const dispatch = useDispatch();
  const fileSrc = useSelector((state) => state.file.fileSrc);
  const name = useSelector((state) => state.file.name);
  const { isConfirmed, userAlreadyAgreed } = useConfirm();

  const handleFileDrop = async (e: File[] | FileList | null) => {
    dispatch(setFileSrc(""));
    if (e && e.length) {
      const file = e[0];
      const { name, type } = file;
      const pathToFile = window.URL.createObjectURL(file);
      dispatch(setFileSrc(pathToFile));
      dispatch(setFileName(name));
      dispatch(setFileExtension(type));
      if (!userAlreadyAgreed) {
        const confirmed = await isConfirmed();
        if (!confirmed) {
          return;
        }
      }
      dispatch(setAppState("request"));
    }
  };

  return {
    fileSrc,
    name,
    handleFileDrop,
  };
};

export default useFile;
